













































































































import { Component, Vue } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import notificationModule from "./notification-module";
import { NotificationDto } from "./notification-dto";
@Component({
  components: { VuePerfectScrollbar }
})
export default class Notification extends Vue {
  public menu: boolean = false;
  public data() {
    return {
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: "",
      showFullSearch: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false
    };
  }

  public NotificacionVista(item: NotificationDto) {
    notificationModule.NotificacionVista(item);
  }

  public NotificacionCancelarCitaModificada(item: NotificationDto) {
    notificationModule.NotificacionCancelarCitaModificada(item);
  }
  public get unreadNotifications() {
    return notificationModule.unReadNotifications;
  }

  public getDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  }
  public elapsedTime(startTime: any) {
    let x = new Date(startTime);
    let now = new Date();
    var timeDiff = now.getTime() - x.getTime();
    timeDiff /= 1000;

    var seconds = Math.round(timeDiff);
    timeDiff = Math.floor(timeDiff / 60);

    var minutes = Math.round(timeDiff % 60);
    timeDiff = Math.floor(timeDiff / 60);

    var hours = Math.round(timeDiff % 24);
    timeDiff = Math.floor(timeDiff / 24);

    var days = Math.round(timeDiff % 365);
    timeDiff = Math.floor(timeDiff / 365);

    var years = timeDiff;

    if (years > 0) {
      return years + (years > 1 ? " años " : " años ") + "";
    } else if (days > 0) {
      return days + (days > 1 ? " dias " : " dia ") + "";
    } else if (hours > 0) {
      return hours + (hours > 1 ? " horas " : " hora ") + "";
    } else if (minutes > 0) {
      return minutes + (minutes > 1 ? " minutos " : " min ") + "";
    } else if (seconds > 0) {
      return seconds + (seconds > 1 ? " futro" : "ahora");
    }

    return "Just Now";
  }
}
